<template>
  <div
    class="article-card"
    :class="{
      'article-card--product': article.structureType === RETAIL_PRODUCT,
      'article-card--recipe': article.structureType === RETAIL_RECIPE,
    }"
  >
    <BaseImage
      v-if="image"
      class="article-card__background-image"
      :image="image"
      :loading="imageLoading"
    />
    <BaseLink
      :url="article.routePath"
      class="article-card__link"
      :aria-label="article.title"
    />
    <div class="article-card__category">
      <Component
        :is="iconMapping[article.structureType]"
        class="article-card__category-icon"
      />
    </div>
    <span class="article-card__title heading heading--h4">
      {{ article.title }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import BaseImage from '@/components/base/BaseImage.vue';
import { type ArticleExcerpt } from '@/types/Article';
import { RETAIL_PRODUCT, RETAIL_RECIPE } from '@/constants/articleTypes';
import IconProduct from '@/assets/icons/product.svg?component';
import IconBlog from '@/assets/icons/blog.svg?component';
import IconChef from '@/assets/icons/chef.svg?component';
import BaseLink from '@/components/base/BaseLink.vue';

const props = defineProps({
  article: {
    type: Object as PropType<ArticleExcerpt>,
    default: () => ({}),
  },
  imageLoading: {
    type: String,
    default: 'lazy',
    validator: (value: string) => ['lazy', 'eager'].includes(value),
  },
});

const iconMapping = {
  retail_article: IconBlog,
  retail_product: IconProduct,
  retail_recipe: IconChef,
};

const image = computed(
  () => props.article.header_image
    ?? props.article.hero_landscape_image
    ?? props.article.details_main_image,
);
</script>

<style lang="scss" scoped>
.article-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(0 12px 27px rgba($black, 0.19));
  transition: filter 0.3s;

  &__background-image {
    @include exception-left(0);

    position: absolute;
    z-index: map-get($z-index, 'negative');
    inset-block-start: 0;
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }

  &__link {
    position: absolute;
    inset: 0;
    z-index: map-get($z-index, 'positive');
  }

  &__category {
    display: flex;
    block-size: 64px;
    inline-size: 64px;
    background-color: rgba($black, 0.45);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }

  &__category-icon {
    fill: $white;
  }

  &__title {
    position: absolute;
    inset-block-end: 8px;
    opacity: 0;
    margin: 0;
    z-index: map-get($z-index, 'positive');
    transition: transform 0.5s;
  }

  &:hover {
    filter: drop-shadow(5px 22px 27px rgba($black, 0.46));
  }

  @include hover-style {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        0deg,
        rgba($black, 20%) 0%,
        rgba($white, 0%) 100%
      );
    }

    .article-card__title {
      transform: translateY(-8px);
      opacity: 1;
    }
  }
}

.article-card--product {
  background-color: $primary-color-800;

  .article-card__background-image {
    @include exception-left;

    inline-size: 80%;
    block-size: 80%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}

.article-card--recipe {
  .article-card__category-icon {
    fill: none;
  }
}
</style>
